<template>
  <div class="d-flex flex-column flex-root" id="payment-success">
    <div class="d-flex flex-row-fluid bgi-size-cover bgi-position-center">
      <!-- begin:: Content -->
      <div class="d-flex flex-column flex-row-fluid text-center">
        <v-row wrap>
          <v-col align="center" cols="12">
            <v-img
              width="100"
              height="100"
              src="/media/error/payment-success.png"
            />
          </v-col>
        </v-row>
        <p></p>
        <p class="display-4">
          Transaction completed successfully.
          <br />Thank you.
          <br />
        </p>
      </div>
      <!-- end:: Content -->
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/error/error-6.scss";
#payment-success{
  .animate-button1 {
    display: inline-block;
    margin: 0 0.5rem;
    color: white;

    animation: bounce; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 2s; /* don't forget to set a duration! */
  }
}
</style>

<script>
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";
export default {
  name: "Error-6",
  mounted() {
    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
  },
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/error/bg6.jpg";
    },
  },
};
</script>
